.delivery-table {
    width: 100%;
    border-collapse: collapse;

    & th {
        padding: 2rem;
        background-color: #0a659c;
        color: #fff;
        font-weight: 700;
        font-size: 18px;
        font-family: Arial;

        &:nth-child(1) {
            width: 300px;
            background-color: #0a659c !important;
        }
        &:nth-child(2) {
            padding-left: 20px;
            width: 338px;
            background-color: #2488c5;
        }
        &:nth-child(3) {
            width: 282px;
            background-color: #f56a1e;
        }
    }
    & td {
        padding: 2rem;
        background-color: #fff;
        color: #5b605f;
        text-align: center;
        font-weight: 700;
        font-size: 18px;
        font-family: Arial;
    }
}