.lightbox {
    position: relative;
    padding: 42px 57px;;
    box-shadow: var(--shadowOutset);
}

.lightbox__close {
    position: absolute;
    top: -12px;
    right: -12px;
    width: 24px;
    height: 24px;
    background-image: url(../images/lightbox-close.svg);
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
}