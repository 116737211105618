.error {
    padding: 0 10px 0;
    width: 100%;
}

.error__content-wrap {
    margin: 0 auto;
    padding-bottom: 80px;
    padding-top: 80px;
    max-width: 1000px;
    width: 100%;
    text-align: center;
}

.error__title {
    color: #d3d3d3;
    font-family: Arial;
    font-size: 288px;
    font-weight: 700;
    margin-bottom: 79px;
}

.error__text {
    color: #333333;
    font-family: Arial;
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
}