:root {
    --basketHeight: 82px;
}

.basket {
    height: 82px;
    width: 100%;
    text-align: center;
    overflow: hidden;
}

.basket__sections-wrap {
    height: calc(var(--basketHeight)*2);
    position: relative;
    transition-duration: var(--transitionDuration);
    &--open {
        transform: translateY(-50%);
    }
}

.basket__front {
    height: var(--basketHeight);
    padding: 1rem;
    background-image: url(../images/basket-1.png);
    background-repeat: no-repeat;
    background-position: center 1rem;
    padding-top: 44px;
}

.basket__show-items {
    color: #f08b23;
    font-family: "PFDinTextCondProMedium";
    font-size: 18px;
    font-weight: 500;
    text-transform: uppercase;
    /*border-bottom: 1px dotted;*/
    position: relative;
    &:hover {
        color: #f4af2a;
    }
    &::after {
        content: '';
        display: none;
        position: absolute;
        height: 100%;
        width: 7px;
        background-image: url(../images/basket-2.png);
        background-position: center 5px;
        background-repeat: no-repeat;
        top: 0;
        right: -1em;
    }
}

.basket__back {
    height: var(--basketHeight);
    padding: 1rem;
}

.basket__count {
    font-size: 12px;
    line-height: 12px;
    font-family: "PFDinTextCondProMedium";
    text-transform: uppercase;
}

.basket__cost {
    font-size: 18px;
    text-transform: uppercase;
    font-family: "PFDinTextCondProMedium";
}

.basket__order {
    color: #f08b23;
    font-family: "PFDinTextCondProMedium";
    font-size: 18px;
    font-weight: 500;
    text-transform: uppercase;
    /*border-bottom: 1px dotted;*/
    &:hover {
        color: #f4af2a;
    }
}