.pagination {
    width: 100%;
    display: flex;
    justify-content: center;

    & * {
        font-family: Arial;
    }
}

.pagination__item {
    color: #0a659c;
    font-size: 18px;
    font-weight: 700;
    width: 40px;
    line-height: 40px;
    display: block;
    text-align: center;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    box-sizing: content-box;
    background-clip: content-box;

    &[onclick] {
        pointer-events: none;
        color: #4d484a;
    }
}

.pagination__item--active,
.pagination__item:hover {
    background-color: #ededed;
    color: #4d484a;
}
