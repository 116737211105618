.slideshow {
    padding-top: 62px;
    padding-bottom: 42px;

    @apply --container;
}

.slideshow__content-wrap {
    position: relative;

    @apply --contentWrap;
}

.slideshow__items-wrap {
}

.slideshow__item {
    display: flex;
    flex-direction: row-reverse;
    padding: 40px 30px 30px 0;
    height: 400px;
    background-color: #fff;
}

.slideshow__item-content-wrap {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    padding-left: 90px;
    height: 100%;
}

.slideshow__item-image-wrap {
    display: block;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
    width: 252px;
}

.slideshow__item-image {
    display: block;
    max-width: 100%;
    max-height: 100%;
}

.slideshow__item-suptitle {
    top: 0;
    margin-bottom: 16px;
    color: #262626;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 30px;
    font-family: "PFDinTextCondProMedium";
}

.slideshow__item-title {
    margin-bottom: 32px;
    color: #262626;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 48px;
    font-family: "PFDinTextCondProMedium";
}

.slideshow__item-description {
    margin-bottom: 44px;
    min-height: 2em;
    color: #262626;
    font-weight: 400;
    font-size: 13px;
    font-family: Arial;
    line-height: 18px;
}

.slideshow__item-caption {
    display: flex;
    margin-top: auto;
    margin-left: -90px;
    width: calc(100% + 90px);
}

.slideshow__item-table {
    position: relative;
    flex-grow: 1;
    width: calc(100% - 220px);
    border-radius: 0 6px 6px 0;
    background-color: #222;

    &::before {
        position: absolute;
        top: -10px;
        left: 0;
        display: block;
        width: 10px;
        height: 10px;
        background-image: url(../images/slideshow-3.png);
        background-position: left top;
        background-repeat: no-repeat;
        content: '';
    }

    &::after {
        position: absolute;
        bottom: -10px;
        left: 0;
        display: block;
        width: 10px;
        height: 10px;
        background-image: url(../images/slideshow-3.png);
        background-position: left bottom;
        background-repeat: no-repeat;
        content: '';
    }

    & thead {
    }

    & tbody {
    }

    & th {
        padding: 17px 10px 0;
        color: #fff;
        text-transform: uppercase;
        white-space: nowrap;
        font-weight: 500;
        font-size: 16px;
        text-align: left;
        font-family: "PFDinTextCondProMedium";
    }

    & td {
        padding: 10px 10px 10px;
        color: #fff;
        text-align: left;
        text-transform: uppercase;
        font-weight: 700;
        font-size: 48px;
        font-family: "PFDinTextCondProBold";

        & sup {
            text-transform: none;
        }
    }
}

.slideshow__item-cost {
    flex-shrink: 0;
    padding-left: 20px;
}

.slideshow__item-sum {
    color: #000;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 40px;
    font-family: "PFDinTextCondProMedium";
}

.slideshow__item-button {
    display: inline-block;
    padding: 12px 32px 6px;
    border-radius: 24px;
    background-image: linear-gradient(to top, #eb5f1a 0%, #f4af2a 100%);
    color: #fff;
    text-transform: uppercase;
    text-shadow: 1px 1px 1px rgba(0, 0, 1, 0.2);
    font-weight: 500;
    font-size: 30px;
    font-family: "PFDinTextCondProMedium";
    line-height: 1;

    &:hover {
        background-image: linear-gradient(to top, #f56b26 0%, #fcbc3f 100%);
    }
}

.slideshow__nav {
    position: absolute;
    top: 31px;
    right: auto;
    bottom: auto !important;
    left: 0 !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 25px;
    width: 61px !important;
    height: auto;
    border-radius: 0 6px 6px 0;
    background-image: linear-gradient(to left, #e9eaec 0%, #e8e9eb 73%, #d7d7d8 98%, #e9eaec 100%);

    &::before {
        position: absolute;
        top: -10px;
        left: 0;
        display: block;
        width: 10px;
        height: 10px;
        background-image: url(../images/slideshow-4.png);
        background-position: left top;
        background-repeat: no-repeat;
        content: '';
    }

    &::after {
        position: absolute;
        bottom: -10px;
        left: 0;
        display: block;
        width: 10px;
        height: 10px;
        background-image: url(../images/slideshow-4.png);
        background-position: left bottom;
        background-repeat: no-repeat;
        content: '';
    }

    & .swiper-pagination-bullet {
        margin: 5px 0 !important;
        width: 9px;
        height: 9px;
        border: 2px solid #3c3c3c;
        background-color: #fff;
        opacity: 1;
        transition-duration: var(--transitionDuration);

        &:hover {
            border-color: #f08d23;
            background-color: #f08d23;
        }

        &.swiper-pagination-bullet-active {
            border-color: #f08d23;
            background-color: #f08d23;
        }
    }
}