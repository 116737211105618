.process {
    width: 100%;
    padding: 0 10px;
}

.process__content-wrap {
    width: 100%;
    max-width: 1000px;
    margin: 0 auto;
    margin-bottom: 80px;
    border-top: 9px solid #fff;
    border-bottom: 9px solid #fff;
    padding: 70px 0 50px;
}

.process__title {
    margin: 0 0 40px;
    text-align: center;
    text-transform: uppercase;
    font-size: 30px;
}

.process__items-wrap {
    display: flex;
    justify-content: space-between;
    width: 100%;
    counter-reset: processItem;
}

.process__item {
    position: relative;
    width: calc((100% - 5rem)/6);

    &::before {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 1rem;
        width: 42px;
        height: 42px;
        border: 7px solid #fff;
        border-radius: 100%;
        background-color: #eb631b;
        color: #fff;
        content: counter(processItem);
        counter-increment: processItem;
        text-align: center;
        font-size: 18px;
        font-family: Arial;
    }
}

.process__item-title {
    display: block;
    margin: 0 0 17px;
    margin-bottom: 1rem;
    color: #eb631b;
    text-transform: uppercase;
    font-size: 20px;
}

.process__item-text {
    color: #5b605f;
    font-size: 12px;
    font-family: Arial;
    line-height: 18px;
}