.product {
    @apply --container;
    margin-bottom: 80px;
}

.product__content-wrap {
    @apply --contentWrap;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    & .seo-text {
        padding: 0;
    }
}

.product__col {
    &:first-child {
        width: 420px;
    }

    &:last-child {
        width: 500px;
    }
}