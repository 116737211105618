.series-inline {
    width: 100%;
    padding: 0 10px;
    display: flex;
    flex-wrap: wrap;

    & * {
        font-family: Arial;
    }
}

.series-inline__content-wrap {
    border-top: 1px solid #e3e2e0;
    padding-top: 21px;
    padding-bottom: 21px;
    width: 100%;
    max-width: 1000px;
    margin: 0 auto;
}

.series-inline__title {
    font-weight: 400;
    line-height: 30px;
    color: #333;
    font-size: 10px;
    text-transform: uppercase;
    width: 100%;
}

.series-inline__item {
    color: #0f69a8;
    font-size: 12px;
    position: relative;
    white-space: nowrap;
    display: inline-block;
    float: left;
    line-height: 30px;
}

.series-inline__item:hover {
    color: #f56a1e;
}

.series-inline__item:not(:last-of-type) {
    padding-right: 1em;
}

.series-inline__item:not(:last-of-type)::after {
    content: '';
    width: 1px;
    height: 1px;
    border-radius: 50%;
    background-color: #333;
    position: absolute;
    top: 50%;
    right: .5em;
    transform: translate(50%, -50%);
}
