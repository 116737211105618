.page-title {
    @apply --container;
    padding-top: 40px;
    padding-bottom: 44px;
}

.page-title__text {
    @apply --contentWrap;
    text-align: left;
    font-weight: 300;
    font-size: 48px;
}

.page-title__accent {
    color: #ed6d1d;
}