.cart-form {
    width: 100%;
    margin-bottom: 80px;

    & label {
        color: #252627;
        font-family: Arial;
        font-size: 11px;
        font-weight: 700;
        line-height: 14px;
        text-transform: uppercase;
        display: block;
    }

    & input {
        color: #2f3236;
        font-family: Arial;
        font-size: 12px;
        font-weight: 400;
        line-height: 26px;
    }

    & .NFI-wrapper {
        position: relative;
        width: 100%;
    }

    & .NFI-button {
        position: absolute !important;
        padding: 6px 12px;
        color: #666;
        right: 5px;
        cursor: pointer;
        top: 5px;
        font-size: 9px;
        font-weight: 700;
        text-transform: uppercase;
        box-shadow: 1px 1px 0 #fff, inset 1px 1px 1px #fff;
        border-radius: 2px;
        border: 1px solid #d8dcdf;
        background-image: linear-gradient(to top, rgba(255, 255, 255, .11) 0%, rgba(51, 69, 82, .11) 100%);
        border: 1px solid #d6dcdf;
    }

    & .NFI-filename {
        padding: 0 12px;
    }
}

.cart-form__row {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}

.cart-form__hidden-wrap {
    width: 100%;

    &[data-company] {
        display: none;
    }

    &[data-single] {
        display: block;
    }
}

.cart-form__col {
    width: calc((100% - 80px)/3);
}

.cart-form__cost {
    width: 100%;
    color: #292929;
    font-family: Arial;
    font-size: 14px;
    font-weight: 700;
    line-height: 18px;
    text-transform: uppercase;
    padding-top: 20px;
    margin-bottom: 20px;
}

.cart-form__cost-count {
    font-size: 18px;
    font-family: Arial;
    padding-left: 1em;

    & > * {
        font-family: Arial;
    }
}

.cart-form__send {
    display: inline-block;
    padding: 12px 32px 6px;
    border-radius: 24px;
    background-image: linear-gradient(to top, #eb5f1a 0%, #f4af2a 100%);
    color: #fff;
    text-transform: uppercase;
    text-shadow: 1px 1px 1px rgba(0, 0, 1, 0.2);
    font-weight: 500;
    font-size: 30px;
    font-family: "PFDinTextCondProMedium";
    line-height: 1;

    &:hover {
        background-image: linear-gradient(to top, #f56b26 0%, #fcbc3f 100%);
    }
}