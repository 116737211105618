.form-one-click {
    width: 100%;
    text-align: left;

    & * {
        font-family: Arial;
    }
}

.form-one-click__suptitle {
    margin-bottom: 9px;
    color: #2f3236;
    text-transform: uppercase;
    font-weight: 400;
    font-size: 14px;
}

.form-one-click__title {
    margin-bottom: 28px;
    padding-bottom: 30px;
    border-bottom: 1px solid #e3e3e3;
    color: #000000;
    font-weight: 400;
    font-size: 36px;
}

.form-one-click__content {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.form-one-click__left-col {
    flex-shrink: 0;
    margin-right: 19px;
    width: 242px;
}

.form-one-click__image-wrap {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 29px;
    padding: 20px;
    width: 100%;
    height: 167px;
    border: 1px solid #e6e6e6;
    background-color: #ffffff;
}

.form-one-click__image {
    display: block;
    max-width: 100%;
    max-height: 100%;
}

.form-one-click__text {
    position: relative;
}

.form-one-click__info {
    position: relative;
    width: 100%;
    color: #333333;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;

    @nest .form-one-click[data-form-success] & {
        display: none;
    }
}

.form-one-click__fieldset {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    margin-top: 31px;
    width: 100%;
}

.form-one-click__fieldset-title {
    margin-bottom: 9px;
    width: 100%;
    color: #2f3236;
    text-transform: uppercase;
    font-weight: 400;
    font-size: 12px;
    line-height: 30px;
}

input.form-one-click__field {
    flex-grow: 1;
    margin-right: 10px;
    padding: 0 10px;
    width: 240px;
    height: 50px;
    border-radius: 0;
    background-color: #332f30;
    background-color: #ffffff;
    background-color: #fff;
    box-shadow: inset 1px 1px 4px rgba(0, 0, 0, 0.24);
    color: #333333;
    font-weight: 400;
    font-size: 22px;
    font-family: Arial;
}

.form-one-click__send-button {
    padding: 15px 24px 11px;
    border-radius: 10px;
    background-color: #e6e6e6;
    background-image: linear-gradient(to top, #1265a0 0%, #2a75aa 49%, #4286b5 100%);
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.5), inset 0 30000px 0 250px rgba(255, 255, 255, 0.05);
    color: #ffffff;
    text-transform: uppercase;
    white-space: nowrap;
    font-weight: 500;
    font-size: 30px;
    font-family: PFDinTextCondProRegular;
}

.form-one-click__status {
    display: none;
    color: #f9452c;
    font-size: 13px;
    font-weight: 400;
    line-height: 34px;
    width: 100%;
}

.form-one-click__footer {
    width: 100%;
}

.form-one-click__footer-suptitle {
    margin-bottom: 7px;
    color: #2f3236;
    text-transform: uppercase;
    font-weight: 400;
    font-size: 12px;
    line-height: 30px;
}

.form-one-click__footer-cost {
    color: #2f3236;
    font-weight: 700;
    font-size: 36px;
    line-height: 30px;
}

.form-one-click__thanks {
    display: none;
    padding-top: 138px;
    background-image: url(../images/form-one-click.thanks.png);
    background-position: left top;
    background-repeat: no-repeat;

    @nest .form-one-click[data-form-success] & {
        display: block;
    }
}

.form-one-click__thanks-title {
    margin-bottom: 31px;
    color: #2f3236;
    font-weight: 400;
    font-size: 20px;
}

.form-one-click__thanks-text {
    color: #333333;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
}








