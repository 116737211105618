.cart-products {
    position: relative;
    width: 100%;
}

.cart-products__empty {
    position: relative;
    width: 100%;
    text-align: center;
}

.cart-products__empty-title {
    color: #292929;
    font-size: 48px;
    font-weight: 300;
    text-transform: uppercase;
    padding-bottom: 211px;
    background-image: url(../images/cart-products.cart.png);
    background-position: center calc(100% - 50px);
    background-repeat: no-repeat;
}

.cart-products__empty-ps {
    font-family: Arial;
    font-size: 16px;

    & > a {
        text-decoration: none;
    }
}

.cart-products__table {
    position: relative;
    width: 100%;
    border-collapse: collapse;

    & thead {
    }

    & tbody {
    }

    & tfoot {
        & tr {
            text-align: right;
        }

        & td {
            border-bottom: none;
        }
    }

    & tr {
    }

    & th {
        padding: 10px 10px 10px 0;
        text-align: left;
        text-transform: uppercase;
        font-weight: 700;
        font-size: 10px;
        font-family: Arial;
        line-height: 14px;

        &:not(:first-child) {
            border-bottom: 2px solid #dedede;
        }
    }

    & td {
        border-bottom: 1px solid #dedede;

        & > * {
            font-family: Arial;
        }
    }
}

.cart-products__item {
}

.cart-products__item-image {
    padding: 27px 27px 27px 0;

    & img {
        width: 61px;
        height: 67px;
        border: 1px solid #dedede;
        background-color: #fff;
        object-position: center;
        object-fit: contain;
    }
}

.cart-products__item-name {
    padding: 27px 1rem 27px 0;
    text-align: left;

    & a {
        color: #0a659c;
        text-decoration: underline;
        font-weight: 400;
        font-size: 14px;
        font-family: Arial;
        line-height: 18px;

        &:hover {
            color: #f08b23;
        }
    }
}

.cart-products__item-cost {
    padding: 27px 1rem 27px 0;
    color: #292929;
    text-align: left;
    text-transform: uppercase;
    font-weight: 400;
    font-size: 12px;
    font-family: Arial;
    line-height: 14px;
}

.cart-products__item-count {
    padding: 27px 1rem 27px 0;
}

.cart-products__item-count-minus {
    padding: 0 .5em;
    line-height: 32px;

    &:hover {
        color: #f08b23;
    }
}

input.cart-products__item-count-field {
    padding: 0 .5em;
    width: 54px;
    height: 32px;
    color: #2f3236;
    text-align: center;
    font-weight: 400;
    font-size: 12px;
    font-family: Arial;
    line-height: 26px;
}

.cart-products__item-count-plus {
    padding: 0 .5em;
    line-height: 32px;

    &:hover {
        color: #f08b23;
    }
}

.cart-products__item-sum {
    padding: 27px 1rem 27px 0;
    color: #4e4e4e;
    text-align: left;
    text-transform: uppercase;
    font-weight: 400;
    font-size: 12px;
    font-family: Arial;
    line-height: 14px;
    width: 140px;
}

.cart-products__summary {
    padding-top: 60px;
    padding-bottom: 20px;
    border-bottom: none !important;
    color: #292929;
    text-align: right;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 14px;
    font-family: Arial;
    line-height: 18px;
}

.cart-products__summary-count {
    padding-left: 1em;
    font-size: 18px;
    font-family: Arial;

    & > * {
        font-family: Arial;
    }
}

.cart-products__order {
    display: inline-block;
    padding: 12px 32px 6px;
    border-radius: 24px;
    background-image: linear-gradient(to top, #eb5f1a 0%, #f4af2a 100%);
    color: #fff;
    text-transform: uppercase;
    text-shadow: 1px 1px 1px rgba(0, 0, 1, 0.2);
    font-weight: 500;
    font-size: 30px;
    font-family: "PFDinTextCondProMedium";
    line-height: 1;

    &:hover {
        background-image: linear-gradient(to top, #f56b26 0%, #fcbc3f 100%);
    }
}

.cart-products__item-remove {
}

.cart-products__item-remove-button {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    background-image: url(../images/cart-products.close.png);
}