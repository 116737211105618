.sort {
    padding: 0 10px;
    width: 100%;
}

.sort__content-wrap {
    max-width: 1000px;
    margin: 0 auto;
    width: 100%;
}

.sort__params-wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 31px;
    width: 100%;
    color: #333;
    white-space: nowrap;
    font-weight: 400;
    font-size: 14px;
    line-height: 34px;

    & * {
        font-family: Arial;
    }
}

.sort__sort {
    display: flex;
    align-items: center;
}

.sort__sort-label {
    margin-right: 12px;
    font-weight: 400;
}

.sort__sort-dropdown {
    width: 200px;
}

.sort__view-wrap {
    display: flex;
    align-items: center;
}

.sort__view-wrap > *:not(:first-child) {
    margin-left: 20px;
}

.sort__view-label {
    font-weight: 400;
}

.sort__view-btn {
    width: 18px;
    height: 18px;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
}

.sort__view-btn--tiles {
    background-image: url(../images/sort-1.svg);
}

.sort__view-btn--tiles[data-active],
.sort__view-btn--tiles:hover {
    background-image: url(../images/sort-1--active.svg);
}

.sort__view-btn--cards {
    background-image: url(../images/sort-2.svg);
}

.sort__view-btn--cards[data-active],
.sort__view-btn--cards:hover {
    background-image: url(../images/sort-2--active.svg);
}

.sort__view-btn--list {
    background-image: url(../images/sort-3.svg);
}

.sort__view-btn--list[data-active],
.sort__view-btn--list:hover {
    background-image: url(../images/sort-3--active.svg);
}

.sort__container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
}

.sort__sidebar {
    width: 220px;
}

.sort__main {
    width: calc(100% - (220px + 20px));
}

.sort__catalog-wrap {
    width: 100%;
    padding-bottom: 20px;

    &--search {
        margin-bottom: 60px;

        & .catalog-2017 {
            & .catalog-2017__tile {
                width: 235px;
            }
        }
    }
}

.sort__pagination-wrap {
    width: 100%;
    padding-bottom: 30px;
}
/**/

.sort .selectric {
    position: relative;
    width: 202px;
    height: 34px;
    border: none;
    background-color: #fff;
    box-shadow: inset 0 0 0 1px #ececec, 0 1px 0 0 #c9c9c9;
}

.sort .selectric .label {
    display: block;
    overflow: hidden;
    margin: 0 34px 0 1em;
    height: 34px;
    color: #444;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 12px;
    line-height: 34px;
    user-select: none;
}

.sort .selectric .button:after {
    display: none;
}

.sort .selectric .button {
    position: absolute;
    top: 0;
    right: 0;
    display: block;
    width: 34px;
    height: 34px;
    background-image: url(../images/sort-arr.png);
    background-position: center;
    background-repeat: no-repeat;
    box-shadow: inset 0 0 0 1px #ececec;
    color: #bbb;
    font-size: 0;
}

.selectric-items {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: -1;
    display: none;
    border: 1px solid #ececec;
    background: #ffffff;
    box-shadow: 0 0 10px -6px;
}

.selectric-items li {
    display: block;
    padding: 0 1em;
    color: #666;
    line-height: 34px;
    cursor: pointer;
}

.selectric-items li:hover {
    background: #f08b23;
    color: #ececec;
}

.selectric-items li.highlighted {
    background: #f08b23;
    color: #fff;
}

