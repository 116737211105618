.form-on-demand {
    width: 100%;
    text-align: left;
}

.form-on-demand__header {
    margin-bottom: 20px;
    width: 100%;
    border-bottom: 1px solid #e3e3e3;
}

.form-on-demand__suptitle {
    margin-bottom: 13px;
    color: #2f3236;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 14px;
    font-family: Arial;
}

.form-on-demand__title {
    margin-bottom: 20px;
    color: #0a659c;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 24px;
    font-family: Arial;
}

.form-on-demand__content-wrap {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.form-on-demand__image-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
    width: 220px;
    border: 1px solid #e3e2e0;
    background-color: #fff;
    transition-duration: var(--transitionDuration);
}

.form-on-demand__image {
    max-width: 100%;
    max-height: 150px;
}

.form-on-demand__content {
    width: calc(100% - 240px);
}

.form-on-demand__text {
    margin-bottom: 65px;
    color: #666666;
    font-weight: 400;
    font-size: 14px;
    font-family: Arial;
    line-height: 24px;
}

.form-on-demand__fields-wrap {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.form-on-demand__field {
    flex-grow: 1;
    margin-right: 20px;
    padding: 0 1em;
    height: 58px !important;
    border: 1px solid #d2d9dc;
    border-radius: 5px;
    background-color: #ffffff;
    box-shadow: inset 1px 2px 6px rgba(0, 0, 0, 0.1);
    text-transform: uppercase;
    font-weight: 700;
    font-size: 24px;
    font-family: Arial;
}

.form-on-demand__send-button {
    padding: 0 .5em;
    border-radius: 5px;
    background-image: linear-gradient(to top, #eb5f1a 0%, #f4af2a 100%);
    color: #ffffff;
    text-transform: uppercase;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.55);
    font-weight: 500;
    font-size: 28px;
    font-family: "PFDinTextCondProMedium";
    line-height: 58px;

    &:hover {
        background-image: linear-gradient(to top, #f56b26 0%, #fcbc3f 100%);
    }
}

.form-on-demand__thanks {
    display: none;
}

.form-on-demand__thanks-title {
    margin-bottom: 24px;
    color: #2f3236;
    font-weight: 700;
    font-size: 24px;
    font-family: Arial;
}

.form-on-demand__thanks-text {
    color: #666666;
    font-weight: 400;
    font-size: 14px;
    font-family: Arial;
    line-height: 24px;
}