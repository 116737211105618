.series-description {
    position: relative;
    width: 100%;
}

.series-description__accordion {
    width: 100%;
}

.series-description__accordion-item {
    position: relative;
    width: 100%;
}

.series-description__accordion-control {
    @nest .open & {
        border-bottom: none;
        color: #666;
    }

    margin-bottom: .7em;
    border-bottom: 1px dotted;
    color: #0f69a8;
    font-size: 18px;
    font-family: Arial;

    &:hover {
        color: #f08b23;
    }
}

.series-description__accordion-content {
    color: #666;
    font-size: 13px;
    font-family: Arial;
}