.brands-nav {
    width: 100%;
    padding: 0 10px;
}

.brands-nav__content-wrap {
    width: 100%;
    max-width: 1000px;
    margin: 0 auto;
    padding-bottom: 40px;
}

.brands-nav__title {
    color: #2f3236;
    font-family: Arial;
    font-size: 18px;
    font-weight: 700;
    line-height: 18px;
    font-family: Arial;
    margin-bottom: 28px;
}

.brands-nav__items-wrap {
    width: calc(100% + 20px);
    margin-right: -20px;
    display: flex;
    flex-wrap: wrap;
}

.brands-nav__item {
    display: flex;
    width: calc((100% - 20px*8)/8);
    height: 60px;
    border: 1px solid #ececec;
    background-color: #ffffff;
    padding: 10px;
    align-items: center;
    justify-content: center;
    margin: 0 20px 20px 0;

    &:hover {
        border-color: #f08b23;
    }
}

.brands-nav__item-image {
    display: block;
    max-width: 100%;
    max-height: 100%;
}