.category {
    margin-bottom: 100px;
    @apply --container;
}

.category__content-wrap {
    display: flex;
    justify-content: space-between;
    @apply --contentWrap;
}

.category__items-wrap {
    width: 760px;
}

.category__sidebar {
    width: 200px;
}