.series {
    width: 100%;
    position: relative;
    font-size: 13px;
    font-weight: 700;
    line-height: 36px;
    display: flex;
    flex-direction: column;
    margin-bottom: 2rem;
}

.series__title {
    color: #2f3236;
    display: block;
    font-family: Arial;
}

.series__link {
    color: #0a659c;
    font-family: Arial;
    text-decoration: underline;
    &:hover {
        color: #f56a1e;
    }
}