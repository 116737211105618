.cart {
    padding: 70px 10px 0;
    width: 100%;

    &[data-form-success] {
        & .cart-products__order {
            display: none;
        }

        & .cart-products__item-remove {
            display: none;
        }
    }
}

.cart__content-wrap {
    margin: 0 auto;
    padding-bottom: 80px;
    max-width: 1000px;
    width: 100%;
}

.cart__nav {
    display: flex;
    justify-content: center;
    margin-bottom: 40px;
    width: 100%;
    font-size: 48px;
}

.cart__nav-button {
    margin: 0 .25em;
    border-bottom: 2px solid currentColor;
    color: #f56a1e;
    text-transform: uppercase;
    font-weight: 300;
    font-size: 48px;
    font-family: "PFDinTextCondProRegular";

    &--state {
        &--active {
            border-color: transparent;
            color: #292929;
            cursor: default !important;
        }
    }

    &:hover {

        /* border-color: currentColor;
        color: #f56a1e;*/
    }
}

.cart__form-wrap {
    display: none;
    width: 100%;

    @nest .cart[data-form-success] & {
        display: none !important;
    }
}

.cart__success {
    position: relative;
    display: none;
    margin-bottom: 100px;
    padding-top: 138px;
    width: 100%;
    background-image: url(../images/cart.success.png);
    background-position: 128px top;
    padding-left: 128px;
    background-repeat: no-repeat;

    @nest .cart[data-form-success] & {
        display: block;
    }
}

.cart__success-title {
    margin-bottom: 31px;
    color: #2f3236;
    font-weight: 400;
    font-size: 20px;
    font-family: Arial;
    line-height: 48px;
}

.cart__success-subtitle {
    color: #333333;
    font-weight: 400;
    font-size: 16px;
    font-family: Arial;
    line-height: 24px;
}