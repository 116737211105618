.services {
    @apply --container;

    margin-bottom: 80px;
}

.services__content-wrap {
    @apply --contentWrap;

    display: flex;
    justify-content: space-between;
}

.services__item {
    padding-top: 100px;
    width: calc(50% - 1rem);
    background-position: center top;
    background-repeat: no-repeat;

    &--icon {
        &--1 {
            background-image: url(../images/services-1.png);
        }
        &--2 {
            background-image: url(../images/services-2.png);
        }
    }
}

.services__item-title {
    display: block;
    margin: 0 0 20px;
    color: #eb631b;
    text-align: center;
    text-transform: uppercase;
    font-size: 36px;
}

.services__item-caption {
    text-align: center;
    font-size: 14px;
    font-family: Arial;
    line-height: 21px;
}