.header {
    position: relative;
    margin-bottom: 62px;
    background-image: url(../images/header-1.jpg);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    box-shadow: 1px 5px 5px rgba(0, 0, 0, 0.1);

    @apply --container;
}

.header__content-wrap {
    display: flex;
    padding: 2rem 0 .5rem;

    @apply --contentWrap;
}

.header__logo {
    display: block;
    flex-shrink: 0;
    width: 291px;
    height: 88px;
    background-image: url(../images/header-3.png);
    background-position: left center;
    background-size: contain;
    background-repeat: no-repeat;
}

.header__container {
    display: flex;
    flex-grow: 1;
    flex-wrap: wrap;
    justify-content: space-between;
    padding-left: 19px;
}

.header__menu {
    display: flex;
    width: 449px;
}

.header__menu-item {
    display: block;
    flex-grow: 1;
    color: #3a3b3c;
    text-align: center;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 17px;
    font-family: 'PFDinTextCondProMedium';
    line-height: 56px;

    &:hover {
        color: var(--colorPrimary);
    }

    &--state {
        &--active {
            color: var(--colorPrimary);
        }
    }
}

.header__basket-wrap {
    margin-top: -32px;
    margin-bottom: 16px;
    width: 159px;
    height: 82px;
    border-radius: 6px;
    background-color: #ffffff;
}

.header__search-wrap {
    width: 449px;
}

.header__phone {
    color: #292929;
    white-space: nowrap;
    font-size: 30px;
    font-family: 'PFDinTextCondProMedium';

    &:hover {
        color: var(--colorPrimary);
    }
}

.header__phone-sup {
    font-size: .6em;
}

.header__phone-text {
    color: #262626;
    text-align: right;
    font-weight: 400;
    font-size: 13px;
    font-family: Arial;
    line-height: 18px;
}

.header__menu-wrap {
    @apply --contentWrap;
}