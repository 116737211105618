.breadcrumbs {
    @apply --container;
}

.breadcrumbs__content-wrap {
    @apply --contentWrap;
    display: flex;
    justify-content: space-between;
    padding: 2rem 0;
    border-bottom: 1px solid #e3e2e0;
}

.breadcrumbs__col {
    display: flex;
    justify-content: flex-start;
    padding-right: 1rem;
    align-items: center;
    &:first-child {
        width: 420px;
    }
    &:last-child {
        width: 500px;
    }
}

.breadcrumbs__link {
    padding: 0 23px 0 20px;
    background-position: right center;
    background-repeat: no-repeat;
    color: #0f69a8;
    text-decoration: underline;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 10px;
    font-family: Arial;
    line-height: 14px;
    &:first-child {
        padding-left: 0;
    }
    &:last-child {
        padding-right: 0;
    }
    &:not(:last-child) {
        background-image: url(../images/breadcrumbs-1.png);
    }
    &:hover {
        color: #f08b23;
    }
    &--type {
        &--current {
            color: #2f3236 !important;
            text-decoration: none;
        }
    }
}

.breadcrumbs__category {
    padding: 0 23px 0 20px;
    background-position: right center;
    background-repeat: no-repeat;
    &:first-child {
        padding-left: 0;
    }
    &:last-child {
        padding-right: 0;
    }
    &:not(:last-child) {
        background-image: url(../images/breadcrumbs-1.png);
    }
}

.breadcrumbs__category-title {
    color: #2f3236;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 10px;
    font-family: Arial;
    line-height: 14px;
}

.breadcrumbs__category-link {
    color: #0f69a8;
    text-decoration: underline;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 10px;
    font-family: Arial;
    line-height: 14px;
    &:hover {
        color: #f08b23;
    }
}