.variants {
    width: 100%;
    position: relative;
}

.variants__item {
    width: 100%;
    border-top: 1px dotted #5c5c5c;
    padding: 1rem 0 2rem;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.variants__title {
    padding-left: 26px;
    background-position: left center;
    background-repeat: no-repeat;
    color: #2f3236;
    font-family: Arial;
    font-size: 20px;
    font-weight: 400;
    &--icon {
        &--1 {
            background-image: url(../images/variants-1.png);
        }
        &--2 {
            background-image: url(../images/variants-2.png);
        }
    }
}

.variants__list {
    list-style: none;
    width: 180px;
}

.variants__list-item {
    background-image: url(../images/variants-3.png);
    color: #2f3236;
    font-family: Arial;
    font-size: 12px;
    font-weight: 700;
    line-height: 24px;
    padding-left: 22px;
    background-position: left center;
    background-repeat: no-repeat;
}