.brands {
    @apply --container;

    margin-bottom: 80px;
}

.brands__content-wrap {
    @apply --contentWrap;
}

.brands__title {
    margin: 0 0 40px;
    text-align: center;
    text-transform: uppercase;
    font-size: 36px;
    line-height: 48px;
}

.brands__title-accent {
    color: #eb631b;
}

.brands__items-wrap {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    padding: 30px;
    width: 100%;
    background-color: #fff;
}

.brands__item {
    margin: 10px;
}