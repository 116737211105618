.form-help {
    position: fixed;
    bottom: 0;
    left: 4rem;
    z-index: 100;
    padding: 20px 30px;
    width: 496px;
    height: 119px;
    background-image: url(../images/form-help-1.png);
    background-position: center bottom;
    background-size: contain;
    background-repeat: no-repeat;
    transform: translateY(100%);
    opacity: 0;
    transition-duration: var(--transitionDuration);

    &--visible {
        transform: translateY(0);
        opacity: 1;
    }
}

.form-help__close {
    position: absolute;
    top: -12px;
    right: -2px;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background-color: #fff;
    background-image: url(../images/form-help-close.svg);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    box-shadow: inset 0 0 0 3px #000;
}

.form-help__fields-wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;

    &[data-thanks] {
        display: none;
    }
}

.form-help__title {
    margin-bottom: 10px;
    color: #ffffff;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 30px;
    font-family: "PFDinTextCondProMedium";
    width: 100%;
}

.form-help__text {
    flex-shrink: 0;
    color: #ffffff;
    text-transform: uppercase;
    white-space: nowrap;
    font-weight: 300;
    font-size: 16px;
    font-family: "PFDinTextCondProRegular";
    line-height: 18px;
}

input.form-help__field {
    flex-grow: 1;
    margin: 0 1rem 0 2rem;
    width: auto;
}

.form-help__button {
    flex-shrink: 0;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background-image: url(../images/form-help-2.png);
    font-size: 0;
}