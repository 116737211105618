@font-face {
    font-family: 'lightcase';
    src: url("../fonts/Lightcase/lightcase.eot?55356177");
    src: url("../fonts/Lightcase/lightcase.eot?55356177#iefix") format("embedded-opentype"), url("../fonts/Lightcase/lightcase.woff?55356177") format("woff"), url("../fonts/Lightcase/lightcase.ttf?55356177") format("truetype"), url("../fonts/Lightcase/lightcase.svg?55356177#lightcase") format("svg");
    font-weight: normal;
    font-style: normal;
}

@font-face {
	font-family: 'PFDinTextCondProMediumItalic ';
	src: url('../fonts/PFDinTextCondPro/PFDinTextCondPro-MediumItalic.eot');
	src: url('../fonts/PFDinTextCondPro/PFDinTextCondPro-MediumItalic.eot?#iefix') format('embedded-opentype'),
		url('../fonts/PFDinTextCondPro/PFDinTextCondPro-MediumItalic.woff') format('woff'),
		url('../fonts/PFDinTextCondPro/PFDinTextCondPro-MediumItalic.ttf') format('truetype');
	font-weight: 500;
	font-style: italic;
}

@font-face {
	font-family: 'PFDinTextCondProXBlack';
	src: url('../fonts/PFDinTextCondPro/PFDinTextCondPro-XBlack.eot');
	src: url('../fonts/PFDinTextCondPro/PFDinTextCondPro-XBlack.eot?#iefix') format('embedded-opentype'),
		url('../fonts/PFDinTextCondPro/PFDinTextCondPro-XBlack.woff') format('woff'),
		url('../fonts/PFDinTextCondPro/PFDinTextCondPro-XBlack.ttf') format('truetype');
	font-weight: 900;
	font-style: normal;
}

@font-face {
	font-family: 'PFDinTextCondProRegular';
	src: url('../fonts/PFDinTextCondPro/PFDinTextCondPro-Regular.eot');
	src: url('../fonts/PFDinTextCondPro/PFDinTextCondPro-Regular.eot?#iefix') format('embedded-opentype'),
		url('../fonts/PFDinTextCondPro/PFDinTextCondPro-Regular.woff') format('woff'),
		url('../fonts/PFDinTextCondPro/PFDinTextCondPro-Regular.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'PFDinTextCondProXThin';
	src: url('../fonts/PFDinTextCondPro/PFDinTextCondPro-XThin.eot');
	src: url('../fonts/PFDinTextCondPro/PFDinTextCondPro-XThin.eot?#iefix') format('embedded-opentype'),
		url('../fonts/PFDinTextCondPro/PFDinTextCondPro-XThin.woff') format('woff'),
		url('../fonts/PFDinTextCondPro/PFDinTextCondPro-XThin.ttf') format('truetype');
	font-weight: 100;
	font-style: normal;
}

@font-face {
	font-family: 'PFDinTextCondProItalic';
	src: url('../fonts/PFDinTextCondPro/PFDinTextCondPro-Italic.eot');
	src: url('../fonts/PFDinTextCondPro/PFDinTextCondPro-Italic.eot?#iefix') format('embedded-opentype'),
		url('../fonts/PFDinTextCondPro/PFDinTextCondPro-Italic.woff') format('woff'),
		url('../fonts/PFDinTextCondPro/PFDinTextCondPro-Italic.ttf') format('truetype');
	font-weight: normal;
	font-style: italic;
}

@font-face {
	font-family: 'PFDinTextCondProThinItalic';
	src: url('../fonts/PFDinTextCondPro/PFDinTextCondPro-ThinItalic.eot');
	src: url('../fonts/PFDinTextCondPro/PFDinTextCondPro-ThinItalic.eot?#iefix') format('embedded-opentype'),
		url('../fonts/PFDinTextCondPro/PFDinTextCondPro-ThinItalic.woff') format('woff'),
		url('../fonts/PFDinTextCondPro/PFDinTextCondPro-ThinItalic.ttf') format('truetype');
	font-weight: 100;
	font-style: italic;
}

@font-face {
	font-family: 'PFDinTextCondProXBlackItalic';
	src: url('../fonts/PFDinTextCondPro/PFDinTextCondPro-XBlackItalic.eot');
	src: url('../fonts/PFDinTextCondPro/PFDinTextCondPro-XBlackItalic.eot?#iefix') format('embedded-opentype'),
		url('../fonts/PFDinTextCondPro/PFDinTextCondPro-XBlackItalic.woff') format('woff'),
		url('../fonts/PFDinTextCondPro/PFDinTextCondPro-XBlackItalic.ttf') format('truetype');
	font-weight: 900;
	font-style: italic;
}

@font-face {
	font-family: 'PFDinTextCondProBold';
	src: url('../fonts/PFDinTextCondPro/PFDinTextCondPro-Bold.eot');
	src: url('../fonts/PFDinTextCondPro/PFDinTextCondPro-Bold.eot?#iefix') format('embedded-opentype'),
		url('../fonts/PFDinTextCondPro/PFDinTextCondPro-Bold.woff') format('woff'),
		url('../fonts/PFDinTextCondPro/PFDinTextCondPro-Bold.ttf') format('truetype');
	font-weight: bold;
	font-style: normal;
}

@font-face {
	font-family: 'PFDinTextCondProLightItalic';
	src: url('../fonts/PFDinTextCondPro/PFDinTextCondPro-LightItalic.eot');
	src: url('../fonts/PFDinTextCondPro/PFDinTextCondPro-LightItalic.eot?#iefix') format('embedded-opentype'),
		url('../fonts/PFDinTextCondPro/PFDinTextCondPro-LightItalic.woff') format('woff'),
		url('../fonts/PFDinTextCondPro/PFDinTextCondPro-LightItalic.ttf') format('truetype');
	font-weight: 300;
	font-style: italic;
}

@font-face {
	font-family: 'PFDinTextCondProThin';
	src: url('../fonts/PFDinTextCondPro/PFDinTextCondPro-Thin.eot');
	src: url('../fonts/PFDinTextCondPro/PFDinTextCondPro-Thin.eot?#iefix') format('embedded-opentype'),
		url('../fonts/PFDinTextCondPro/PFDinTextCondPro-Thin.woff') format('woff'),
		url('../fonts/PFDinTextCondPro/PFDinTextCondPro-Thin.ttf') format('truetype');
	font-weight: 100;
	font-style: normal;
}

@font-face {
	font-family: 'PFDinTextCondProLight';
	src: url('../fonts/PFDinTextCondPro/PFDinTextCondPro-Light.eot');
	src: url('../fonts/PFDinTextCondPro/PFDinTextCondPro-Light.eot?#iefix') format('embedded-opentype'),
		url('../fonts/PFDinTextCondPro/PFDinTextCondPro-Light.woff') format('woff'),
		url('../fonts/PFDinTextCondPro/PFDinTextCondPro-Light.ttf') format('truetype');
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: 'PFDinTextCondProXThinItalic';
	src: url('../fonts/PFDinTextCondPro/PFDinTextCondPro-XThinItalic.eot');
	src: url('../fonts/PFDinTextCondPro/PFDinTextCondPro-XThinItalic.eot?#iefix') format('embedded-opentype'),
		url('../fonts/PFDinTextCondPro/PFDinTextCondPro-XThinItalic.woff') format('woff'),
		url('../fonts/PFDinTextCondPro/PFDinTextCondPro-XThinItalic.ttf') format('truetype');
	font-weight: 100;
	font-style: italic;
}

@font-face {
	font-family: 'PFDinTextCondProBoldItalic';
	src: url('../fonts/PFDinTextCondPro/PFDinTextCondPro-BoldItalic.eot');
	src: url('../fonts/PFDinTextCondPro/PFDinTextCondPro-BoldItalic.eot?#iefix') format('embedded-opentype'),
		url('../fonts/PFDinTextCondPro/PFDinTextCondPro-BoldItalic.woff') format('woff'),
		url('../fonts/PFDinTextCondPro/PFDinTextCondPro-BoldItalic.ttf') format('truetype');
	font-weight: bold;
	font-style: italic;
}

@font-face {
	font-family: 'PFDinTextCondProMedium';
	src: url('../fonts/PFDinTextCondPro/PFDinTextCondPro-Medium.eot');
	src: url('../fonts/PFDinTextCondPro/PFDinTextCondPro-Medium.eot?#iefix') format('embedded-opentype'),
		url('../fonts/PFDinTextCondPro/PFDinTextCondPro-Medium.woff') format('woff'),
		url('../fonts/PFDinTextCondPro/PFDinTextCondPro-Medium.ttf') format('truetype');
	font-weight: 500;
	font-style: normal;
}
