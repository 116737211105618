.projects-samples {
    @apply --container;

    margin-bottom: 80px;
}

.projects-samples__content-wrap {
    @apply --contentWrap;
}

.projects-samples__title {
    margin: 0 0 40px;
    text-align: center;
    text-transform: uppercase;
    font-size: 30px;
}

.projects-samples__items-wrap {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.projects-samples__item {
    width: calc((100% - 4rem)/3);
    text-align: center;
    cursor: pointer;
}

.projects-samples__item-title {
    display: inline-block;
    @nest .projects-samples__item:hover & {
        color: #eb631b;
    }

    margin-bottom: 30px;
    border-bottom: 1px dotted;
    color: #345982;
    text-transform: uppercase;
    font-size: 20px;
    transition-duration: var(--transitionDuration);

}

.projects-samples__item-image {
    max-width: 100%;
}