.no-price {
    position: relative;
    margin-bottom: 2rem;
    color: #292929;
    font-weight: 700;
    text-transform: uppercase;
    width: 100%;
}

.no-price__title {
    font-size: 24px;
    font-family: Arial;
    display: block;
}

.no-price__phone {
    color: #292929;
    font-family: Arial;
    font-size: 36px;
    &:hover {
        color: #f08b23;
    }
}