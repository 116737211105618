.filter {
    width: 100%;
    position: relative;
    z-index: 100;

    & * {
        font-family: Arial;
    }
}

.filter__fieldset {
    background-color: #fff;
    box-shadow: 0 0 0 1px #f2f2f2;
    padding: 15px 12px;
    position: relative;
}

.filter__fieldset-row {
    display: flex;
    align-items: center;
    height: 30px;
    position: relative;
}

.filter__fieldset-row--flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: auto;
}

.filter__fieldset-row--prerange {
    margin-bottom: 32px;
}

.filter__range-input-wrap {
    height: auto;
    position: relative;
    width: 82px;
}

.filter__range-inputs-divider {
    line-height: 23px;
}

.filter__title {
    color: #333;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    margin-bottom: 11px;
}

.filter__fieldset-items-wrap {
    width: 100%;
    position: relative;
    max-height: calc(30px * 8);
    overflow-y: auto;
}

.filter__input {
}

.filter__input[type="number"] {
    width: 100%;
    height: 23px;
    background-color: #fff;
    border: none;
    box-shadow: 0 0 0 1px rgba(217, 217, 217, 0.5), 0 -1px 0px #d2d2d2;
    border-radius: 1px;
    text-align: left;
    color: #333;
    font-size: 12px;
    font-weight: 400;
    line-height: 23px;
    padding: 0 1em 0 2em;
}

.filter__input[type="checkbox"] {
    width: 14px;
    height: 14px;
    margin-right: 8px;
}

.filter__input[type="checkbox"]+label::before {
    content: '';
    width: 14px;
    height: 14px;
    display: block;
    position: absolute;
    left: 0;
    top: 50%;
    background-color: #fff;
    border: 1px solid #ececec;
    box-shadow: 0 1px 0 #d2d2d2;
    transform: translateY(-50%);
    border-radius: 1px;
    cursor: pointer;
}

.filter__input[type="checkbox"]+label::after {
    content: '';
    width: 0;
    height: 14px;
    display: block;
    position: absolute;
    left: 0;
    top: 50%;
    background-image: url(../images/filter-check.png);
    background-position: left center;
    background-repeat: no-repeat;
    transform: translateY(-50%);
    border-radius: 1px;
    transition: .3s;
    cursor: pointer;
}

.filter__input[type="checkbox"]:checked+label::after {
    width: 14px;
}

.filter__input--range {
}

.filter__range {
    width: 100%;
    clear: both;
    height: 7px;
    background-color: #fbfbfb;
    box-shadow: inset 0 0 4px rgba(222, 222, 222, 0.56), inset 0 1px 0 #bfbfbf, 0 1px 0 #fff;
}

.filter__range-bar {
    position: absolute;
    height: 8px;
    height: 7px;
    background-color: #f56a1e;
    box-shadow: 0 1px 0 #ffffff;
}

.filter__range-grip {
    position: absolute;
    width: 13px;
    height: 18px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    transform: translateY(-5.5px);
}

.filter__range-grip--left {
    background-image: url(../images/filter-arr-left.png);
}

.filter__range-grip--right {
    background-image: url(../images/filter-arr-right.png);
}

.filter__label {
    color: #333;
    font-size: 12px;
    font-weight: 400;
    line-height: 14px;
}

.filter__label--inset {
    position: absolute;
    line-height: 23px;
    color: #a8b0ae;
    top: 0;
    left: .5em;
}

.filter__label-helper {
    background-image: url(../images/filter-helper.png);
    width: 12px;
    height: 12px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    display: inline-block;
    margin-left: 8px;
    cursor: help;
}

.filter__buttons-wrap {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
}

.filter__send {
    line-height: 40px;
    background-image: linear-gradient(to top, #eb5f1a 0%, #f4af2a 100%);
    border-radius: 10px;
    white-space: nowrap;
    color: #fff;
    font-size: 13px;
    font-weight: 400;
    text-transform: uppercase;
    padding: 0 15px;
}

.filter__cancel {
    line-height: 38px;
    border-radius: 10px;
    white-space: nowrap;
    color: #333;
    border: 1px solid #333;
    font-size: 13px;
    font-weight: 400;
    text-transform: uppercase;
    padding: 0 15px;
}

.filter__tooltip {
    position: absolute;
    left: 225px;
    top: calc(50% - 16px);
    background-color: #f08b23;
    display: flex;
    white-space: nowrap;
    padding: 10px 10px;
    width: auto;
    color: #fff;
    text-align: center;
    font-size: 12px;
    animation-name: filterHideTooltop;
    animation-delay: 10s;
    animation-duration: .1s;
    animation-fill-mode: forwards;

    &::after {
        position: absolute;
        left: -9px;
        width: 0;
        height: 0;
        border-width: 5px;
        border-style: solid;
        border-color: transparent #f08b23 transparent transparent;
        content: "";
    }
}

@keyframes filterHideTooltop {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
        pointer-events: none;
    }
}

.filter__tooltip-link {
    margin-left: 10px;
    color: #fff;
    text-decoration: underline;

    &:hover {
        text-decoration: none;
    }
}

