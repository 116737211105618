.gallery-series{
    width: 100%;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 2rem;
}
.gallery-series__item{
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    border: 1px solid #e3e2e0;
    margin-bottom: 12px;
    cursor: pointer;
    transition-duration: var(--transitionDuration);
    & > img {
        max-width: 100%;
        max-height: 100%;
    }
    &:first-child {
        width: 100% !important;
        height: 260px !important;
    }
    &:hover {
        border-color: #f08b23;
    }
}