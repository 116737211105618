.setup {
    width: 100%;
    margin-bottom: 2rem;
    font-size: 18px;
    font-weight: 700;
    line-height: 30px;
    text-transform: uppercase;
    border: 3px solid #d6d3d3;
    padding: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    flex-direction: column;
}

.setup__title {
    color: #0a659c;
    text-decoration: underline;
    font-family: Arial;
    &:hover {
        color: #f08b23;
    }
}

.setup__cost {
    color: #2f3236;
    font-family: Arial;
}