.delivery {
    @apply --container;
}

.delivery__content-wrap {
    @apply --contentWrap;

    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding-bottom: 100px;
}

.delivery__col {
    &:first-of-type {
        width: 280px;
    }
    &:last-of-type {
        margin-bottom: 40px;
        width: calc(100% - 360px);
    }
}

.delivery__title {
    padding-top: 120px;
    color: #292929;
    font-weight: 300;
    font-style: italic;
    font-size: 30px;
    font-family: "PFDinTextCondProRegular";
}