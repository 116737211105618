.product-series {
    @apply --container;

    margin-bottom: 80px;
}

.product-series__content-wrap {
    @apply --contentWrap;

    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.product-series__col {
    &:first-child {
        width: 280px;
    }
    &:last-child {
        width: calc(100% - 360px);
    }
}