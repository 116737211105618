.footer {
    @apply --container;
    background-image: url(../images/footer-3.jpg);
    background-attachment: fixed;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

.footer__content-wrap {
    @apply --contentWrap;
}

.footer__nav-wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.footer__to-top {
    display: block;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-image: url(../images/footer-1.svg);
    background-size: contain;
    background-repeat: no-repeat;
}

.footer__nav {
    display: flex;
    justify-content: flex-end;
}

.footer__nav-link {
    padding-top: 60px;
    padding-bottom: 60px;
    padding-left: 2em;
    color: #f3a929;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 18px;
    font-family: "PFDinTextCondProMedium";
    &--state {
        &--active {
            color: #efefef;
        }
    }
    &:hover {
        color: #efefef;
    }
}

.footer__menu {
    display: flex;
    border-top: 1px solid #404040;
    border-bottom: 1px solid #404040;
}

.footer__menu-link {
    flex-grow: 1;
    padding-top: 60px;
    padding-bottom: 60px;
    color: #efefef;
    text-align: center;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 18px;
    font-family: "PFDinTextCondProMedium";
    &--state {
        &--active {
            color: #f3a929;
        }
    }
    &:hover {
        color: #f3a929;
    }
}

.footer__contacts {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 60px 0;
}

.footer__logo {
    display: block;
    width: 285px;
    background-image: url(../images/footer-2.png);
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    color: #fff;
    text-align: center;
    text-transform: uppercase;
    white-space: nowrap;
    font-style: italic;
    font-size: 36px;
    font-family: 'PFDinTextCondProBold';
    line-height: 56px;
}

.footer__call-block {
    position: relative;
}

.footer__phone {
    display: block;
    margin-bottom: 12px;
    color: #fff;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 24px;
    font-family: Arial;
    &:hover {
        color: #f3a929;
    }
}

.footer__phone-sup {
    font-size: .6em;
}

.footer__phone-desc {
    color: #7b7b7b;
    font-weight: ;
    font-style: italic;
    font-size: 14px;
    font-family: Arial;
}

.footer__copy {
    color: #7b7b7b;
    font-weight: ;
    font-style: italic;
    font-size: 14px;
    font-family: Arial;
    line-height: 21px;
}