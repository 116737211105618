.project-sample {
    width: 100%;
    text-align: left;

    & .js-tabs-height {
        height: 466px !important;
    }
    &--area {
        &--104 {}
        &--210 {}
        &--301 {}
    }
}

.project-sample__title {
    color: #2c363f;
    text-transform: uppercase;
    font-size: 36px;
    line-height: 48px;
}

.project-sample__nav {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 20px;
    width: 100%;
    border-bottom: 5px solid #f3f3f3;
    list-style: none;
}

.project-sample__nav-button {
    padding-top: 1rem;
    padding-bottom: 1rem;
    cursor: pointer;

    &:not(:last-child) {
        padding-right: 2rem;
    }
}

.project-sample__nav-button-text {
    @nest .project-sample__nav-button:hover & {
        color: #eb631b;
    }
    @nest .project-sample__nav-button.active & {
        color: #eb631b;
    }

    border-bottom: 1px dotted;
    color: #345982;
    text-transform: uppercase;
    font-size: 20px;
    transition-duration: var(--transitionDuration);
}

.project-sample__items-wrap {
    width: 100%;
}

.project-sample__item {
    width: 100%;
    height: auto;

    &--1 {}
    &--2 {}
    &--3 {}
}

.project-sample__item-nav {
    display: flex;
    justify-content: flex-start;
    width: 100%;
    list-style: none;
}

.project-sample__item-nav-button {
    padding-top: 1rem;
    padding-bottom: 1rem;
    cursor: pointer;

    &:not(:last-child) {
        padding-right: 2rem;
    }
}

.project-sample__item-nav-button-text {
    @nest .project-sample__item-nav-button:hover & {
        color: #eb631b;
    }
    @nest .project-sample__item-nav-button.active & {
        color: #eb631b;
    }

    border-bottom: 1px dotted;
    color: #345982;
    text-transform: uppercase;
    font-size: 30px;
    transition-duration: var(--transitionDuration);
}

.project-sample__item-tabs-wrap {
    width: 100%;
}

.project-sample__item-tab {
    overflow-y: auto;
    width: 100%;
    height: 400px;

    & table {
        background-color: #f6f5f5;

        & thead {}
        & tbody {
            & td {
                &:last-child {
                    background-color: #95979e;
                }
            }
            & th {
                &+td {
                    background-color: #d94436 !important;
                }
            }
        }
        & tfoot {
            background-color: #4285d9;

            & td {
                color: #fff;
            }
        }
        & tr {}
        & th {
            border-color: #fff;

            &[colspan] {
                text-align: center;
                text-transform: uppercase;
            }
        }
        & td {
            border-color: #fff;

            &:last-child {
                color: #fff;
                text-align: center;
            }
        }
    }
}