.characteristics {
    width: 100%;
    position: relative;
    border-top: 3px solid #d6d3d3;
    padding-top: 2rem;
    margin-bottom: 2rem;
}

.characteristics__table {
    width: 100%;
    & caption {
        color: #2f3236;
        font-family: Arial;
        font-size: 20px;
        font-weight: 400;
        text-align: left;
        margin-bottom: 26px;
    }
    & tbody {}
    & tr {
        &:nth-child(odd) {
            background-color: #e3e2e0;
        }
    }
    & th {
        text-align: left;
        color: #666;
        font-family: Arial;
        font-size: 14px;
        font-weight: 400;
        padding: 10px;
    }
    & td {
        text-align: center;
        color: #666;
        font-family: Arial;
        font-size: 14px;
        font-weight: 400;
        padding: 10px;
    }
}