.search {
    position: relative;
    z-index: 0;
    display: flex;
    width: 100%;
    box-shadow: 1px 5px 5px rgba(0, 0, 0, 0.05);
    &::after {
        position: absolute;
        top: -2px;
        left: -1px;
        z-index: -1;
        display: block;
        width: 101%;
        height: 113%;
        background-image: url(../images/search-3.png);
        background-position: left top;
        background-size: auto;
        background-repeat: no-repeat;
        content: '';
    }
    & ::-webkit-input-placeholder {
        font-style: italic;
    }
    & ::-moz-placeholder {
        font-style: italic;
    }
    & :-moz-placeholder {
        font-style: italic;
    }
    & :-ms-input-placeholder {
        font-style: italic;
    }
}

.search__field {
    flex-grow: 1;
    border-radius: 6px 0 0 6px;
    border: 1px solid #d1cfcf;
    border-right-color: transparent;
    background-color: #fff;
    font-family: Arial;
    font-size: 15px;
    padding: 0 23px;
    &:focus {
        border-color: var(--colorPrimary);
    }
}

.search__btn {
    width: 65px;
    height: 50px;
    border-radius: 0 6px 6px 0;
    background-image: url(../images/search-2.png), url(../images/search-1.png);
    background-position: center, center;
    background-size: 32px 32px, cover;
    background-repeat: no-repeat, no-repeat;
}