.add-to-cart {
    position: relative;
    margin-bottom: 2rem;
    padding-top: 2rem;
    width: 100%;
    border-top: 1px solid #e3e2e0;
}

.add-to-cart__content {
    position: relative;

    @nest .add-to-cart[data-form-success] & {
        display: none;
    }
}

.add-to-cart__row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    width: 100%;
}

.add-to-cart__cost {
    flex-grow: 1;
    color: #292929;
    font-weight: 700;
    font-size: 40px;
    font-family: Arial;
}

.add-to-cart__summary {
    position: relative;
    display: flex;
    flex-grow: 1;
    align-items: center;
    justify-content: space-between;
}

.add-to-cart__summary-label {
    position: absolute;
    top: -1em;
    left: 0;
    width: 54px;
    color: #2f3236;
    text-align: center;
    text-transform: uppercase;
    font-weight: 400;
    font-size: 10px;
    font-family: Arial;
    line-height: 14px;
}

input.add-to-cart__summary-field {
    padding: 0 .5em;
    width: 54px;
    height: 35px;
    border: 1px solid #d2d2d2;
    box-shadow: inset 1px 5px 5px rgba(0, 0, 0, 0.05);
    color: #2f3236;
    text-align: center;
    font-weight: 400;
    font-size: 12px;
    font-family: Arial;

    &:focus {
        border-color: #f08b23;
    }
}

.add-to-cart__summary-button {
    display: inline-block;
    padding: 16px 32px 10px;
    border-radius: 24px;
    background-image: linear-gradient(to top, #eb5f1a 0%, #f4af2a 100%);
    color: #fff;
    text-transform: uppercase;
    text-shadow: 1px 1px 1px rgba(0, 0, 1, 0.2);
    font-weight: 500;
    font-size: 24px;
    font-family: "PFDinTextCondProMedium";
    line-height: 1;

    &:hover {
        background-image: linear-gradient(to top, #f56b26 0%, #fcbc3f 100%);
    }
}

.add-to-cart__buy-btn {
    display: inline-block;
    padding: 18px 32px 12px;
    border-radius: 24px;
    background-image: linear-gradient(to top, #0a659c 0%, #2a8ecb 100%);
    color: #fff;
    text-transform: uppercase;
    text-shadow: 1px 1px 1px rgba(0, 0, 1, 0.2);
    font-weight: 500;
    font-size: 14px;
    font-family: "PFDinTextCondProMedium";
    line-height: 1;

    &:hover {
        background-image: linear-gradient(to top, #f56b26 0%, #fcbc3f 100%);
    }
}

.add-to-cart__message {
    position: relative;
    display: block;
    display: none;

    @nest .add-to-cart[data-form-success] & {
        display: block;
    }
}

.add-to-cart__message-title {
    color: #292929;
    font-family: Arial;
    font-size: 32px;
    font-weight: 700;
    line-height: 46px;
    margin-bottom: 21px;
}