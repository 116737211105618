.series-characteristics {
    position: relative;
    margin-bottom: 2rem;
    padding-top: 2rem;
    width: 100%;
    border-top: 1px solid #e3e2e0;
}

.series-characteristics__table {
    width: 100%;

    & thead {
        & th {
            padding: 10px 10px 10px 0;
            color: #666;
            vertical-align: top;
            text-align: left;
            text-transform: uppercase;
            font-weight: 400;
            font-size: 11px;
            font-family: Arial;
            line-height: 16px;
        }
    }
    & tbody {
        & tr {
            &>* {
                padding: 10px 10px 10px 0;
                text-align: left;
            }
            &:not(:last-child) th {
                border-bottom: 1px dotted #e3e2e0;
            }
            &:not(:last-child) td {
                border-bottom: 1px dotted #e3e2e0;
            }
        }
        & th {
            color: #262626;
            text-transform: uppercase;
            font-weight: 400;
            font-size: 20px;
            font-family: Arial;

            & a {
                color: #0f69a8;
                text-decoration: underline;
                text-transform: uppercase;
                font-weight: 400;
                font-size: 16px;
                font-family: Arial;

                &:hover {
                    color: #f08b23;
                }
            }
            &:last-child {
                & button {
                    border-bottom: 1px dotted;
                    color: #0f69a8;
                    text-transform: none;
                    font-weight: 400;
                    font-size: 16px;
                    font-family: Arial;

                    &:hover {
                        color: #f08b23;
                    }
                }
            }
        }
        & td {
            color: #262626;
            font-weight: 400;
            font-size: 18px;
            font-family: Arial;
        }
    }
}