.catalog {
    @apply --container;
}

.catalog__content-wrap {
    @apply --contentWrap;
    padding: 0 0 100px;
}

.catalog__items-wrap {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.catalog__item {
    margin: 0 20px 60px;
    width: calc((100% - 120px)/3);
    text-align: center;
}

.catalog__item-image-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto 20px;
    width: 117px;
    height: 130px;
    background-image: url(../images/catalog-1.png);
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
}

.catalog__item-image {
    max-width: 80px;
    max-height: 80px;
}

.catalog__item-title {
    @nest .catalog__item:hover & {
        color: var(--colorPrimary);
    }
    color: #0a659c;
    text-decoration: underline;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 30px;
    font-family: "PFDinTextCondProMedium";
    transition-duration: var(--transitionDuration);
}

.catalog__item-caption {
    margin-top: 20px;
    color: #262626;
    font-weight: 400;
    font-size: 12px;
    font-family: Arial;
    line-height: 18px;
}