.category-item {
    position: relative;
    width: 100%;
    &:not(:last-child) {
        margin-bottom: 50px;
        padding-bottom: 50px;
        border-bottom: 1px solid #bdc3c7;
    }
    &--search {
        &:before {
            position: absolute;
            left: -1.5em;
            color: #222;
            content: counter(searchResults)'.';
            counter-increment: searchResults;
            text-transform: uppercase;
            font-weight: 700;
            font-size: 24px;
            font-family: Arial;
        }
    }
}

.category-item__promo {
    display: flex;
    justify-content: space-between;
    padding: 30px;
    width: 100%;
    background-image: linear-gradient(to top, #f3f3f3 0%, #dfdfdf 100%);
}

.category-item__promo-logo {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 161px;
    height: 72px;
    border: 3px solid #d1d1d1;
    background-color: #fff;
}

.category-item__promo-logo-image {
    max-width: 100%;
    max-height: 100%
}

.category-item__brand {
    text-transform: uppercase;
}

.category-item__brand-title {
    color: #2f3236;
    font-weight: 700;
    font-size: 30px;
    font-family: Arial;
}

.category-item__brand-suptitle {
    padding: 1em 0;
    color: #807b6b;
    font-weight: 400;
    font-size: 10px;
    font-family: Arial;
}

.category-item__brand-cost {
    color: #444848;
    font-weight: 400;
    font-size: 14px;
    font-family: Arial;
}

.category-item__promo-readmore {
    padding-left: 24px;
    background-image: url(../images/category-item-5.png);
    background-position: left top;
    background-repeat: no-repeat;
    color: #0a659c;
    text-decoration: underline;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 12px;
    font-family: Arial;
    line-height: 18px;
    &:hover {
        color: #f08b23;
    }
}

.category-item__header {
    display: flex;
    margin-bottom: 60px;
    width: 100%;
}

.category-item__header-col {
    width: 50%;
    background-position: right center;
    background-repeat: no-repeat;
    &:not(:last-child) {
        background-image: url(../images/category-item-3.png);
    }
}

.category-item__suptitle {
    color: #666;
    text-transform: uppercase;
    font-weight: 400;
    font-size: 11px;
    font-family: Arial;
    line-height: 24px;
}

.category-item__title {
    color: #f56a1e;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 30px;
    font-family: Arial;
}

.category-item__options {
    display: flex;
    padding-left: 20px;
}

.category-item__options-headers-wrap {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    width: 50%;
}

.category-item__options-title {
    padding-left: 16px;
    background-image: url(../images/category-item-1.png);
    background-position: left center;
    background-repeat: no-repeat;
    color: #666;
    text-transform: uppercase;
    white-space: nowrap;
    font-weight: 400;
    font-size: 12px;
    font-family: Arial;
    line-height: 18px;
}

.category-item__options-caption {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-left: 10px;
    width: 50%;
}

.category-item__options-desc {
    color: #2f3236;
    font-weight: 700;
    font-size: 12px;
    font-family: Arial;
    line-height: 18px;
}

.category-item__content {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.category-item__image-wrap {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    width: 180px;
    height: 180px;
}

.category-item__image {
    max-width: 100%;
    max-height: 100%;
}

.category-item__table {
    width: calc(100% - 200px);
    & thead {
        & th {
            vertical-align: top;
            text-align: center;
            font-size: 10px;
            &:first-child {
                text-align: left;
            }
            &:not(:first-child, :last-child) {
                color: #0a659c;
            }
        }
    }
    & tbody {
        & tr {
            &:last-child {
                &>* {
                    border-bottom: 20px solid #f3f3f3;
                }
            }
        }
    }
    & tfoot {
        & td {
            padding-top: 20px;
            border-top: 1px solid #bdc3c7;
            text-align: left;
            font-size: 11px;
            & a {
                color: #0f69a8;
                text-decoration: underline;
                text-transform: uppercase;
                font-size: 10px;
                &:hover {
                    color: #f08b23;
                }
            }
        }
    }
    & tr {
        &:nth-child(even) {
            background-color: #eee;
            & td {
                background-color: #f56a1e;
                color: #fff;
            }
        }
    }
    & th {
        padding: 5px;
        padding: 5px;
        width: 25%;
        color: #666;
        text-align: left;
        text-transform: uppercase;
        font-weight: 700;
        font-size: 12px;
        font-family: Arial;
        line-height: 16px;
        &:last-child {
            text-align: center;
            white-space: nowrap;
            & button {
                border-bottom: 1px dotted;
                color: #0f69a8;
                text-transform: none;
                font-weight: 400;
                font-family: Arial;
                &:hover {
                    color: #f08b23;
                }
            }
        }
    }
    & td {
        padding: 5px;
        width: 25%;
        text-align: center;
        white-space: nowrap;
        font-size: 12px;
        font-family: Arial;
        line-height: 16px;
    }
    & a {
        color: #0f69a8;
        text-decoration: underline;
        font-weight: 400;
        font-family: Arial;
        &:hover {
            color: #f08b23;
        }
    }
}