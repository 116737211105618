.search-results-2017 {
    padding: 0 10px;
    width: 100%;

    & .sort {
        padding: 0;
    }
}

.search-results-2017__content-wrap {
    margin: 0 auto;
    max-width: 1000px;
    width: 100%;
}

.search-results-2017__resilt {
    margin-bottom: 47px;
    color: #333333;
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    font-family: Arial;
}

.search-results-2017__sort-wrap {
    width: 100%;
}