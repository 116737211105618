.contacts {
    @apply --container;
}

.contacts__content-wrap {
    @apply --contentWrap;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding-bottom: 100px;
}

.contacts__map {
    width: calc(100% - 300px);
    min-height: 320px;
}

.contacts__text {
    flex-shrink: 0;
    flex-basis: 300px;
    padding-right: 40px;
    width: 300px;
}