.modifications {
    position: relative;
    display: flex;
    justify-content: space-between;
    margin-bottom: 2rem;
    padding-top: 2rem;
    width: 100%;
    border-top: 3px solid #d6d3d3;
}

.modifications__tabs-nav {
    flex-shrink: 0;
    list-style: none;
}

.modifications__tabs-nav-item {
    padding: 2rem 20px;
    background-color: transparent;
    color: #0a659c;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 18px;
    font-family: Arial;
    cursor: pointer;
    transition-duration: var(--transitionDuration);

    &:hover {
        background-color: #eee;
        color: #f56a1e;
    }

    &.active {
        background-color: #eee;
        color: #f56a1e;
    }
}

.modifications__tabs-items-wrap {
    flex-grow: 1;
    width: 100%;
}

.modifications__tabs-item {
    padding: 2rem 20px;
    width: 100%;
    background-color: #eee;
}

.modifications__table {
    width: 100%;

    & tbody {
        & th {
            &:last-child {
                text-transform: none;
            }
        }
    }

    & tr {
        &:nth-child(even) {
            background-color: #e3e2e0;
        }
    }

    & th {
        color: #313330;
        text-transform: uppercase;
        font-weight: 700;
        font-size: 10px;
        font-family: Arial;
        line-height: 18px;
    }

    & td {
        padding: 10px;
        color: #666;
        text-align: center;
        font-weight: 400;
        font-size: 12px;
        font-family: Arial;
    }

    &--type {
        &--mods {
        }

        &--cost {
            & td {
                &:first-child {
                    text-align: left;
                }
            }
        }
    }

    & a {
        color: #0f69a8;
        text-decoration: underline;

        &:hover {
            color: #f08b23;
        }
    }
}