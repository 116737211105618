.form-order {
    @apply --container;
    border-bottom: 2px solid #fff;
    background-image: url(../images/form-order-bgd.jpg);
    background-attachment: fixed;
    background-repeat: repeat;
}

.form-order__content-wrap {
    width: 100%;
    max-width: 1000px;
    margin: 0 auto;
    padding: 70px 0;
    position: relative;
}

.form-order__success {
    padding-bottom: 100px;
    width: 100%;
    height: 100%;
    display: none;
    background-image: url(../images/form-order.success.png);
    background-position: center top;
    background-repeat: no-repeat;
    padding-top: 148px;
    text-align: center;

    @nest .form-order[data-form-success] & {
        display: block;
    }
}

.form-order__success-title {
    color: #ffffff;
    font-family: Arial;
    font-size: 36px;
    font-weight: 400;
    line-height: 48px;
    margin-bottom: 37px;
}

.form-order__success-subtitle {
    color: #ffffff;
    font-family: Arial;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
}

.form-order__content {
    position: relative;

    @nest .form-order[data-form-success] & {
        display: none;
    }
}

.form-order__title {
    margin-bottom: 70px;
    color: #fff;
    text-align: center;
    text-transform: uppercase;
    font-size: 36px;
    font-family: PFDinTextCondProBold;
    line-height: 48px;
}

.form-order__subtitle {
    margin: 0 0 45px;
    margin-bottom: 70px;
    color: #e3e2e0;
    text-align: center;
    font-size: 16px;
    font-family: Arial;
    line-height: 24px;
}

.form-order__fields-wrap {
    position: relative;
    margin: 0 auto;
    max-width: 490px;
    width: 100%;
}

.form-order__field {
    @nest [data-form] & {
        padding: 0 210px 0 40px;
        width: 100%;
        height: 83px !important;
        border: 5px solid #d9d8d7;
        border-radius: 20px !important;
        background: #fff;
        color: #2b2829;
        font-size: 24px;
    }
}

.form-order__button {
    position: absolute;
    top: 15px;
    right: 19px;
    padding: 0 14px;
    height: 54px;
    outline: none;
    border: none;
    border-bottom: 4px solid #b44b14;
    border-radius: 4px;
    background: #eb631b;
    color: #fff;
    text-transform: uppercase;
    font-size: 20px;
    cursor: pointer;

    &:hover {
        background: #fb8140;
    }
}