.main-menu {
    position: relative;
    top: 62px;
    border-bottom: 2px solid #d1d0d0;
    border-radius: 6px;
    width: 100%;
    max-width: 1000px;
    margin: -62px auto 0;
    z-index: 1;
    overflow: hidden;

    &:hover {
        z-index: 10;
        overflow: visible;
    }
}

.main-menu__nav {
    position: relative;
    display: flex;
    width: 100%;
    list-style: none;
}

.main-menu__nav-item {
    position: relative;
    flex-grow: 1;
    overflow: hidden;

    &:first-child {
        border-radius: 6px 0 0 6px;
    }

    &:last-child {
        border-radius: 0 6px 6px 0;
    }

    &--state {
        &--active {
        }
    }
}

.main-menu__nav-trigger {
    position: relative;
    display: block;
    overflow: hidden;
    padding: 11px 10px 0;
    background-image: linear-gradient(to top, #e4e3e3 0%, #fff 85%, #e8e8e8 98%, #d8d8d8 100%);
    background-repeat: no-repeat;
    color: #0a659c;
    text-align: center;
    text-transform: uppercase;
    font-size: 18px;
    font-family: "PFDinTextCondProMedium";

    &:hover {
        padding: 0px 10px 11px;
        background-image: linear-gradient(to top, #eb5f1a 0%, #f4af2a 100%);
        color: #fff;

        &::before {
            opacity: 0;
        }

        &::after {
            opacity: 1;
        }
    }

    &::before {
        display: block;
        margin: 0 auto;
        margin-bottom: 15px;
        width: 17px;
        height: 17px;
        background-image: url(../images/main-menu-2.png);
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
        content: '';
        opacity: 1;
    }

    &::after {
        display: block;
        margin: 0 auto -5px;
        margin-top: 15px;
        width: 26px;
        height: 26px;
        background-image: url(../images/main-menu-3.png);
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
        content: '';
        opacity: 0;
    }

    @nest .active & {
        background-image: linear-gradient(to top, #eb5f1a 0%, #f4af2a 100%);
        color: #fff;
    }

    @nest .main-menu__nav-item--state--active & {
        background-image: linear-gradient(to top, #eb5f1a 0%, #f4af2a 100%);
        color: #fff;
    }
}

.main-menu__submenu {
    position: absolute;
    z-index: -1;
    overflow: hidden;
    width: 100%;
    border-radius: 0 0 6px 6px;
    opacity: 0;
    transition-duration: .3s;
    display: block;
    background-color: #eb601a;

    @nest .main-menu:hover & {
        z-index: 2;
        opacity: 1;
    }
}

.main-menu__submenu-item {
    width: 100%;
}

.main-menu__submenu-item-content-wrap {
    width: 100%;
    display: flex;
}

.main-menu__tab {
    width: 100%;
}

.main-menu__tab-item {
    width: 100%;
    column-count: 4;
}

.main-menu__tab-item-lnk {
    position: relative;
    display: flex;
    align-items: center;
    color: #fff;
    text-decoration: underline;
    white-space: nowrap;
    font-weight: 400;
    font-size: 13px;
    font-family: Arial;
    line-height: 36px;
    break-inside: avoid;

    &:hover {
        color: #f4af2a;

        &::before {
            color: #f4af2a;
        }
    }

    &::before {
        display: block;
        margin-right: 1em;
        width: 3px;
        height: 3px;
        border-radius: 50%;
        background-color: currentColor;
        content: '';
    }
}

.main-menu__submenu-item-desc {
    position: relative;
    overflow: hidden;
    padding: 32px 26px 26px 47px;
    width: 50%;
    background-image: url(../images/main-menu-1.png);
    background-position: left center;
    background-repeat: no-repeat;

    &:last-child {
        flex-shrink: 0;
        width: 480px;
    }
}

.main-menu__submenu-item-desc-title {
    margin-bottom: 20px;
    width: 100%;
    color: #fff;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 18px;
    font-family: "PFDinTextCondProMedium";
}

.main-menu__submenu-item-desc-subtitle {
    color: #fff;
    font-weight: 400;
    font-size: 13px;
    font-family: Arial;
    line-height: 36px;
}

.main-menu__submenu-item-desc-lnks-wrap {
    column-count: 2;
}

.main-menu__submenu-item-desc-lnk {
    display: block;
    margin-bottom: 10px;
    color: #fafafa;
    text-decoration: underline;
    white-space: nowrap;
    font-weight: 400;
    font-size: 18px;
    font-family: Arial;
    line-height: 26px;
    page-break-inside: avoid;

    &:hover {
        color: #f4af2a;
    }

    & sup {
        white-space: nowrap;
        page-break-inside: avoid;
    }
}