.catalog-2017 {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin: 0 -10px;
    width: calc(100% - -10px*2);

    & * {
        font-family: Arial;
    }
}

.catalog-2017__overlay {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 999;
    background-color: rgba(243, 243, 243, 0.5);
    width: 100%;
    height: 100%;
}

.catalog-2017__empty {
    padding-top: 192px;
    padding-bottom: 40px;
    width: 100%;
    background-position: center top;
    background-repeat: no-repeat;
    color: #2f3236;
    text-align: center;
    font-weight: 400;
    font-size: 24px;
    font-family: Arial;
}

.catalog-2017__title {
    margin-bottom: 30px;
    padding: 0 10px;
    width: 100%;
    color: #000000;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 24px;
    font-family: Arial;

    &--margined {
        margin-top: 25px;
    }
}

.catalog-2017__tile {
    margin-right: 10px;
    margin-bottom: 20px;
    margin-left: 10px;
    width: 240px;
    border: 1px solid #f2f2f2;
    background-color: #fff;
    transition: box-shadow .3s;
    display: flex;
    flex-direction: column;

    &:hover {
        box-shadow: 1px 3px 21px rgba(0, 0, 0, 0.25);
    }
}

.catalog-2017__tile-title {
    padding: 9px 12px;
    margin-bottom: auto;
}

.catalog-2017__tile-mark {
    display: block;
    margin-bottom: 3px;
    color: #0a659c;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 10px;
}

.catalog-2017__tile-name {
    display: block;
    color: #000;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 16px;
}

.catalog-2017__tile-img {
    display: block;
    padding: 7px;
    width: 100%;
    height: 163px;
    border-top: 1px solid #f2f2f2;
    border-bottom: 1px solid #f2f2f2;
    background-position: center;
    background-origin: content-box;
    background-size: contain;
    background-repeat: no-repeat;
}

.catalog-2017__tile-desc {
    padding: 13px 11px 15px;
    color: #2f3335;
    list-style: none;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
}

.catalog-2017__tile-desc-param {
    width: 100%;
}

.catalog-2017__tile-caption {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 8px 10px 17px;
    background-color: #ededed;
}

.catalog-2017__tile-cost {
    color: #343434;
    font-weight: 700;
    font-size: 24px;
}

.catalog-2017__tile-readmore {
    display: block;
    width: 40px;
    height: 30px;
    border-radius: 10px;
    background-color: #ececec;
    background-image: url(../images/catalog-2017.arr.png), linear-gradient(to top, #eb5f1a 0%, #f4af2a 100%);
    background-position: center, center;
    background-repeat: no-repeat, no-repeat;
}

.catalog-2017__card {
    margin: 0 10px 30px;
    padding: 20px;
    width: 100%;
    border: 1px solid #f2f2f2;
    background-color: #ffffff;
}

.catalog-2017__card-heading {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 28px;
    width: 100%;
}

.catalog-2017__card-image-wrap {
    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
    margin-right: 20px;
    padding: 10px;
    width: 220px;
    height: 197px;
    border: 1px solid #f7f7f7;
    background-color: #ffffff;
}

.catalog-2017__card-image {
    display: block;
    max-width: 100%;
    max-height: 100%;
}

.catalog-2017__card-description {
    flex-grow: 1;
}

.catalog-2017__card-label {
    color: #333333;
    text-transform: uppercase;
    font-weight: 400;
    font-size: 11px;
    line-height: 24px;
}

.catalog-2017__card-name {
    color: #0a659c;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 30px;
}

.catalog-2017__card-subname {
    margin-bottom: 43px;
    color: #333333;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
}

.catalog-2017__card-param {
    position: relative;
    display: flex;
    justify-content: space-between;
    width: 100%;
    width: 100%;
    text-transform: uppercase;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;

    &::before {
        position: absolute;
        bottom: 0;
        left: 0;
        display: block;
        overflow: hidden;
        width: 100%;
        color: #999999;
        content: '...........................................................................................................................................................................................................................';
    }
}

.catalog-2017__card-param-title {
    position: relative;
    z-index: 1;
    display: block;
    padding-right: 2px;
    background-color: #fff;
    color: #999999;
}

.catalog-2017__card-param-value {
    position: relative;
    z-index: 1;
    display: block;
    padding-left: 2px;
    background-color: #fff;
    color: #333333;
}

.catalog-2017__card-caption {
    padding-bottom: 20px;
    width: 100%;

    & table {
        width: 100%;
        border-collapse: collapse;
        color: #333333;
        font-weight: 400;
        font-size: 13px;
        line-height: 26px;

        & tr {
            &:nth-child(even) {
                background-color: #f7f7f7;
            }
        }

        & th {
            padding: 5px 5px 5px 0;
            border-bottom: 1px solid #e4e3e1;
            text-align: left;
            text-transform: uppercase;
            font-size: 11px;
            line-height: 24px;
        }

        & td {
            padding: 5px;
        }

        & a {
            color: #0a659c;

            &:hover {
                color: #f08b23;
            }
        }
    }
}

.catalog-2017__card-readmore {
    display: block;
    margin: 0 -20px -20px;
    padding: 0 20px 0 50px;
    width: calc(100% + (20px*2));
    border: 1px solid #f2f2f2;
    background-color: #f2f2f2;
    background-image: url(../images/catalog-2017.readmore.png);
    background-position: 20px center;
    background-repeat: no-repeat;
    color: #0a659c;
    text-transform: uppercase;
    font-weight: 400;
    font-size: 12px;
    font-family: Arial;
    line-height: 40px;

    &:hover {
        color: #f08b23;
    }
}

.catalog-2017__list {
    display: flex;
    margin: 0 10px 5px;
    padding: 10px;
    width: 100%;
    width: 100%;
    border: 1px solid #f2f2f2;
    background-color: #ffffff;
}

.catalog-2017__list-image-wrap {
    position: relative;
    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
    width: 80px;
    height: 80px;
    margin-inline-end: 10px;
}

.catalog-2017__list-image {
    display: block;
    max-width: 100%;
    max-height: 100%;
}

.catalog-2017__list-text {
    flex-grow: 1;
    padding: 0 20px;
}

.catalog-2017__list-title {
    margin-bottom: 10px;
    color: #000000;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 16px;
    font-family: Arial;
    transition-duration: .3s;

    @nest .catalog-2017__list:hover & {
        color: #f56a1e;
    }
}

.catalog-2017__list-paragraph {
    color: #2f3335;
    font-weight: 400;
    font-size: 12px;
    font-family: Arial;
    line-height: 18px;
}

.catalog-2017__list-info {
    flex-shrink: 0;
    width: 150px;
}

.catalog-2017__list-cost {
    margin-bottom: 20px;
    color: #000000;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 16px;
    font-family: Arial;
}