.sertification {
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 2rem;
}

.sertification__title {
    color: #2f3236;
    font-family: Arial;
    font-size: 20px;
    font-weight: 400;
}

.sertification__image-wrap {
    width: 162px;
    height: 85px;
    border: 3px solid #d2d2d2;
    background-color: #fff;
    cursor: pointer;
    padding: 12px;
    transition-duration: var(--transitionDuration);
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover {
        border-color: #f08b23;
    }
}

.sertification__image {
    max-width: 100%;
    max-height: 100%;
}