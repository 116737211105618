.demand {
    position: relative;
    margin-bottom: 2rem;
    width: 100%;
}

.demand__title {
    color: #292929;
    font-family: Arial;
    font-size: 46px;
    font-weight: 700;
    display: block;
    margin-bottom: 19px;
}

.demand__button {
    display: inline-block;
    padding: 16px 32px 10px;
    border-radius: 24px;
    background-image: linear-gradient(to top, #eb5f1a 0%, #f4af2a 100%);
    color: #fff;
    text-transform: uppercase;
    text-shadow: 1px 1px 1px rgba(0, 0, 1, 0.2);
    font-weight: 500;
    font-size: 24px;
    font-family: "PFDinTextCondProMedium";
    line-height: 1;
    &:hover {
        background-image: linear-gradient(to top, #f56b26 0%, #fcbc3f 100%);
    }
}