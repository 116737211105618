.seo-text {
    @apply --container;
}

.seo-text__content-wrap {
    &::after {
        display: block;
        clear: both;
        content: '';
    }
    margin-bottom: 50px !important;
    padding-top: 29px;
    border-top: 1px solid #d0d0d0;

    @apply --contentWrap;
}