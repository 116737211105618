.product-info {
    width: 100%;
    margin-bottom: 2rem;

    & tbody {
        width: 100%;
    }
    & tr {
        &:not(:last-child) th{
            border-bottom: 1px dotted #5c5c5c;
        }
        &:not(:last-child) td{
            border-bottom: 1px dotted #5c5c5c;
        }
    }
    & th {
        padding: 20px 20px 20px 23px;
        background-image: url(../images/product-info-1.png);
        background-position: left center;
        background-repeat: no-repeat;
        color: #313330;
        text-align: left;
        font-weight: 400;
        font-size: 16px;
        font-family: Arial;
    }
    & td {
        padding: 20px 0 20px 20px;
        color: #0a659c;
        font-weight: 700;
        font-size: 30px;
        font-family: Arial;
    }
}