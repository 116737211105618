.page-promo {
    @apply --container;
}

.page-promo__content-wrap {
    @apply --contentWrap;

    display: flex;
    justify-content: space-between;
    margin-bottom: 40px;
}

.page-promo__image {
    flex-shrink: 0;
    width: 224px;
}

.page-promo__text-wrap {
    flex-grow: 1;
    padding-left: 40px;
}

.page-promo__slogan {
    margin: 0 0 33px;
    font-style: italic;
    font-size: 24px;
    line-height: 28px;
}