.price {
    width: 100%;
    padding: 0 10px;
    margin-bottom: 80px;
}

.price__content-wrap {
    width: 100%;
    max-width: 1000px;
    margin: 0 auto;
}

.price__title {
    margin: 0 0 40px;
    text-transform: uppercase;
    font-size: 30px;
}

.price__nav {
    display: flex;
    width: 100%;
    list-style: none;
}

.price__nav-item {
    flex-grow: 1;
    padding-bottom: 20px;
    text-align: center;
    cursor: pointer;
}

.price__nav-item-text {
    @nest .price__nav-item:hover & {
        color: #eb631b;
    }

    @nest .price__nav-item.active & {
        color: #eb631b;
    }
    border-bottom: 1px dotted;
    color: #345982;
    text-transform: uppercase;
    font-size: 20px;
    transition-duration: var(--transitionDuration);
}

.price__items-wrap {
    width: 100%;

    &[data-hidden] {
        overflow: hidden;
        max-height: calc(39px * 6);
    }
}

.price__item {
    width: 100%;
}

.price__table {
    width: 100%;
    border-collapse: collapse;
    background-color: #fff;

    & tbody {
    }

    & tr {
    }

    & td {
        padding: 11px;
        border: 1px solid #f7f7f7;
        color: #5b605f;
        font-size: 14px;
        font-family: Arial;

        &:nth-last-child(2) {
            white-space: nowrap;
        }

        &:last-child {
            background: #eb631b;
            color: #fff;
            white-space: nowrap;
        }
    }
}

.price__readmore {
    width: 100%;
    background-color: #383838;
    text-align: center;
    padding: 8px 12px 9px;
    position: relative;

    & span {
        color: #ffffff;
        font-family: Arial;
        font-size: 14px;
        font-weight: 400;
        line-height: 22px;
        border-bottom: 1px dotted;
    }
}